




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class BasePagination extends Vue {
  @Prop(Number) public page!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Number) public perPage!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop({ type: Array, default: () => [50, 100, 150] })
  public perPageOptions!: number[];

  public insertedPageNumber: number = this.page;

  updatePage(page: number): void {
    // Avoid running action on page number that cannot exist
    if (page > this.totalNumberOfPage || page < 1) {
      return;
    }
    this.$emit('update:page', page);
  }
}
